const th = {
  translation: {
    client: {
      api: {
        modal401Title: "การเข้าสู่ระบบหมดอายุ",
        modal401Children: "การเข้าสู่ระบบหมดอายุ กรุณาทำการเข้าสู่ระบบใหม่อีกครั้ง",
        modal401OkButtonLabel: "เข้าสู่ระบบ",
        modal403Title: "เกิดข้อผิดพลาด",
        modal403Children: "บทบาทผู้ใช้งานของคุณไม่มีสิทธิ์ใช้งานส่วนนี้ กรุณาเข้าสู่ระบบอีกครั้ง",
        modal403OkButtonLabel: "เข้าสู่ระบบ",
        modal500Title: "เกิดข้อผิดพลาด",
        modal500OkButtonLabel: "ตกลง",
        modal458Title: "เกิดข้อผิดพลาด",
        modal458Children: "ไม่สามารถแก้ไขแบบประกันได้",
        modal458Children2: "เนื่องจากขณะนี้อยู่นอกช่วงเวลาที่เปิดให้แก้ไขแบบประกันแล้ว",
        modal458Children3: " ข้อมูลที่แก้ไขไปล่าสุดจะไม่ถูกบันทึก",
        modal458OkButtonLabel: "ตกลง",
      },
      pages: {
        auth: {
          SignIn: {
            title: "กรอกอีเมลที่ลงทะเบียนไว้ เพื่อรับรหัส OTP 6 หลัก สำหรับเข้าสู่ระบบ",
            requestOtp: "ส่ง OTP",
            refCode: "รหัสอ้างอิง: {{refCode}}",
            retryOtpIn: "ขอ OTP อีกครั้งในอีก {{retryTime}} วินาที",
            retryOtpAgain: "ส่ง OTP อีกครั้ง {{retryTime}}",
            login: "เข้าสู่ระบบ",
            logoDescription: "เลือกประกันที่ใช่ในแบบของคุณ",
            email: "อีเมล",
            aboutCompany:
              "หากต้องการความช่วยเหลือสามารถติดต่อ {{companyName}} ได้ที่ โทร. {{companyPhoneNumber}} หรืออีเมล {{companyEmail}}",
            enterOtp: "กรอกรหัส OTP",
            enterTitle: "กรอกรหัส OTP จากอีเมล {{email}} (รหัสมีอายุการใช้งาน 5 นาที)",
            noOtp: "ไม่ได้รับรหัส OTP?",
            otp: "OTP",
            otpPlaceholder: "รหัส 6 หลัก",
            emailPlaceholder: "เช่น xxx@companyname.com",
            languageOption: "การตั้งค่าภาษา",
            language: "ภาษา",
            thai: "ภาษาไทย",
            english: "ภาษาอังกฤษ",
            dataSave: "บันทึกข้อมูล",
          },
        },
        main: {
          "about-belive": {
            index: {
              aboutBelive: "เกี่ยวกับ Believe",
              contactInformation: "ข้อมูลการติดต่อ",
              phone: "โทรศัพท์",
              email: "อีเมล",
            },
          },
          account: {
            index: {
              title: "บัญชี",
              profile: "ข้อมูลส่วนตัว",
              setting: "ตั้งค่า",
              aboutBelive: "เกี่ยวกับ Believe",
              signOut: "ออกจากระบบ",
              viewProfile: "ดูข้อมูลส่วนตัว",
              cancel: "ยกเลิก",
              confirm: "ยืนยัน",
              logoutDescrip: "คุณต้องการออกจากระบบและกลับไปหน้าแรกหรือไม่?",
            },
          },
          benefit: {
            index: {
              title: "สวัสดิการบริษัทปี {{year}}",
              canChange: "ปรับเปลี่ยนสวัสดิการบริษัทของปี {{year}} ได้แล้ววันนี้",
              upToDate: "ได้ถึงวันที่ {{date}}",
              pointOfYear: "คะแนนของปี {{year}}",
              point: "คะแนน",
            },
            Footer: {
              title: "สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้",
              ownInsurance: "ประกันของตัวเอง",
              next: "ถัดไป",
            },
            detail: {
              title: "สวัสดิการบริษัทปี {{year}} ของฉัน",
              header: "สวัสดิการปี {{year}}",
              description: "ในปี {{year}} คุณจะได้สวัสดิการข้างล่างนี้เลย แต่คุณยังสามารถแก้ไขได้อยู่",
              yourInsurance: "ประกันของตัวเอง",
              age: "{{age}} ปี",
              insuranceDetail: "รายละเอียดประกัน",
              insuranceDetailSheet: "รายละเอียดแบบประกัน",
              edit: "แก้ไข",
              notConfirm: "ยังไม่ได้ยืนยันการเลือกประกัน",
              notConfirmYet: "คุณยังไม่ได้ยืนยันการแก้ไขแบบประกัน",
              ifYouOut: " ถ้ากดออกจากหน้านี้จะไม่สามารถกู้คืนการแก้ไขที่ทำค้างไว้ได้",
              close: "ปิดหน้าต่างนี้",
              exitNotSave: "ออกและไม่บันทึก",
              dataSave: "ทำรายการสำเร็จ",
              upload: "กรุณาอัปโหลดไฟล์",
              errorDownloadFile: "ไม่สามารถดาวน์โหลดไฟล์ได้",
              ok: "ตกลง",
            },
            InsuranceSelector: {
              title: "เลือกแผนประกันของตัวเอง",
              point: "คะแนน",
              selectInsurance: "เลือกแผนประกัน",
              benefit: "ผลประโยชน์และ ความคุ้มครอง (บาท)",
              insuranceYourSelect: "แผนประกันที่คุณเลือก",
              selectInsurancePlan: "เลือกแผนประกัน",
              needToSelect: `ต้องการเลือกแผน "{{name}}"`,
              package: "Package ประกัน",
            },
            FooterSummary: {
              summaryPoint: "สรุปคะแนน",
              availablePoint: "คะแนนที่มีอยู่",
              usingPoint: "ใช้ไป",
              remainingPoint: "คะแนนคงเหลือ",
              confirm: "บันทึกการเลือกสวัสดิการล่าสุด",
              titleError: "ไม่สามารถเลือกประกันชุดนี้ได้",
              messageError: "เนื่องจากคะแนนที่มีอยู่ของคุณมีไม่เพียงพอ กรุณาแก้ไขรายการประกันที่เลือกอีกครั้ง",
              confirmError: "ตกลง",
              clound: "ส่งข้อมูลการเลือกทั้งหมด",
              modalForceCompleteText1: "ส่งข้อมูลการเลือกทั้งหมด",
              modalForceCompleteText2: "ต้องการยืนยันข้อมูลแผนประกันที่เลือกไว้ทันที โดยไม่รอให้หมดเขตเลือกประกัน",
              modalForceCompleteText3: "ข้อมูลประกันที่ยืนยันแล้วจะไม่สามารถแก้ไขได้อีก",
            },
            view: {
              title: "สวัสดิการบริษัทปี {{year}} ของฉัน",
              selected: "สวัสดิการปี {{year}}",
              view: "ดูรายละเอียดและความคุ้มครองของสวัสดิการปี {{year}} ของคุณ",
              ownInsurance: "รายละเอียดสวัสดิการ",
              years: "({{year}} ปี)",
              insuranceDetail: "รายละเอียดประกัน",
              insuranceDetailSheet: "รายละเอียดแผนประกันและตรวจสุขภาพแบบ PDF",
              viewDetail: "ดูรายละเอียดทั้งหมด",
              custom: "ดูรายละเอียดสวัสดิการทั้งหมด",
              package: "Package ประกัน",
            },
            viewDetail: {
              title: "แผนประกันของตัวเอง",
              detail: "ผลประโยชน์และ ความคุ้มครอง (บาท)",
              select: "โรงพยาบาลที่คุณเลือก",
              requirePoint: "-{{point}} คะแนน",
              package: "Package ประกัน",
            },
            Consent: {
              header: "ยินยอม",
              title: "จากการเลือกปรับเปลี่ยนสวัสดิการแผนประกันของตนเอง ข้าพเจ้าขอยืนยันและตกลงตามข้อตกลงดังต่อไปนี้",
              footer:
                "ในการกดปุ่ม “ยืนยัน” ข้าพเจ้ายืนยันว่าข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไขตามประกาศข้างต้น",
              acceptConsent: "ยอมรับข้อตกลงและเงื่อนไข",
              cancel: "ยกเลิก",
              accept: "ยืนยัน",
            },
          },
          home: {
            index: {
              title: "My Benefit",
            },
            InsuranceItem: {
              warning: {
                header: "ปรับสวัสดิการได้ถึงวันที่ {{date}}",
                title: "ปรับเปลี่ยนสวัสดิการบริษัทได้แล้ววันนี้",
                description: "ปรับเปลี่ยนสวัสดิการให้เหมาะสมกับคุณและตรงกับความต้องการของคุณมากกว่าสวัสดิการพื้นฐาน",
                buttonTxt: "จัดการสวัสดิการปี {{year}}",
              },
              info: {
                header: "มีผลคุ้มครองถึงวันที่ {{date}}",
                title: "สวัสดิการบริษัทปี {{year}}",
                description: "ดูรายละเอียด และความคุ้มครองของสวัสดิการปี {{year}} ของคุณ",
                buttonTxt: "ดูสวัสดิการปี {{year}}",
              },
            },
            EmptyInsuranceItem: {
              title: "กรุณารอการอัพเดทเพิ่มเติม",
              description: "ยังไม่มีรอบสวัสดิการที่สามารถเลือกได้",
              contact: "สอบถามข้อมูลเพิ่มเติมได้ที่",
              phone: "โทรศัพท์",
              email: "อีเมล",
            },
          },
          profile: {
            index: {
              title: "ข้อมูลส่วนตัว",
              age: "อายุ {{age}} ปี",
              contactInformation: "ข้อมูลการติดต่อ",
              email: "อีเมล",
            },
          },
          setting: {
            index: {
              title: "ตั้งค่าระบบ",
              language: "ภาษา",
              currentLanguage: "ภาษาไทย",
              displaySetting: "ตั้งค่าการแสดงผล",
              languageOption: "ตัวเลือกภาษา",
              thai: "ภาษาไทย",
              english: "ภาษาอังกฤษ",
              dataSave: "บันทึกข้อมูล",
            },
          },
        },
      },
      components: {
        common: {
          PopMenu: {
            profile: "โปรไฟล์",
            logout: "ออกจากระบบ",
          },
          Table: {
            dataNotFound: "ไม่พบข้อมูลที่ค้นหา กรุณาตรวจสอบค่าในตัวกรอง",
          },
          Footer: {
            home: "หน้าแรก",
            account: "บัญชี",
          },
        },
      },

      consentRule: {
        1: "ข้าพเจ้าได้อ่าน ทำความเข้าใจและยอมรับในข้อตกลงและเงื่อนไข ขั้นตอนการดำเนินการตลอดจนถึงรายละเอียดของสวัสดิการแผนประกันของตนเองที่ทางบริษัทฯ ได้จัดเตรียมให้และสื่อสารกับข้าพเจ้า โดยข้าพเจ้ามีสิทธิที่จะเข้าร่วมในโปรแกรมสวัสดิการดังกล่าว",
        2: "โดยการกดปุ่ม “ยืนยัน” ข้าพเจ้ามีความประสงค์ที่จะใช้สวัสดิการที่ข้าพเจ้าได้เลือก สวัสดิการที่เลือกนั้นอยู่ในการใช้ดุลยพินิจ การพิจารณา และการตัดสินใจเลือกของข้าพเจ้าเอง",
        3: "ข้าพเจ้ายินดีรับผิดชอบค่าใช้จ่ายส่วนเกินที่เกิดขึ้นจากการเลือกแผนประกันที่มีมูลค่าสูงกว่าแผนดั้งเดิม (default) ตามสิทธิ์ และยอมให้หักเงินค่าใช้จ่ายนี้ผ่านระบบเงินเดือน (payroll)",
        4: "เมื่อการลงทะเบียนเสร็จสิ้นและทางบริษัทฯ ได้ยอมรับการลงทะเบียนดังกล่าว ข้าพเจ้ายอมรับและตกลงว่าข้าพเจ้าไม่สามารถทำการเปลี่ยนแปลงใด ๆ ในสวัสดิการที่ข้าพเจ้าเลือก จนกระทั่งรอบระยะเวลาการเลือกและลงทะเบียนรอบใหม่ตามที่บริษัทฯ กำหนด",
        5: "หากข้าพเจ้าไม่ได้ทำการเลือกสวัสดิการในช่วงระยะเวลาการลงทะเบียนที่บริษัทกำหนด ข้าพเจ้ารับทราบและยอมรับในข้อกำหนดของสิทธิประโยชน์ประกันกลุ่มตามแผนดั้งเดิม (default) ตามนโยบายที่บริษัทจัดให้",
        6: "ข้าพเจ้าเข้าใจและยอมรับว่าการเลือกสวัสดิการบางรายการจะถูกบันทึกเป็นรายได้ เพื่อนำไปคำนวณภาษีเงินได้ส่วนบุคคลในงวดเงินเดือน (payroll) ตามอัตราภาษีที่สรรพากรกำหนด",
        7: "ข้าพเจ้าเข้าใจและยอมรับว่าการมีสิทธิในสวัสดิการต่างๆ ของบริษัทจะสิ้นสุดลง ณ วันที่สิ้นสุดสภาพการจ้างงาน",
        8: "สิทธิประโยชน์ทางด้านประกันนั้นจะเป็นไปตามเงื่อนไขและข้อตกลงของบริษัทประกัน ในกรณีที่มีข้อขัดแย้งระหว่างเงื่อนไขและข้อตกลงของประกัน ให้อ้างอิงเงื่อนไขและข้อตกลงของบริษัทประกันเป็นหลัก",
      },
    },
  },
}

export default th
